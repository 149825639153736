import * as React from 'react';

import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, Lottie, EdTypography } from '@edapp/ed-components';
import LOADING from '@maggie/assets/lottie/loading.json';

export const AppLaunchLoading: React.FC = () => {
  const { t } = useTranslation('learners-experience');
  return (
    <Container
      bgColor="white"
      flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      exit={{ opacity: 0, transition: { ease: 'easeOut', duration: 0.5, delay: 1 } }}
    >
      <Loading data={LOADING} autoplay={true} loop={true} />
      <EdTypography variant="paragraph" color="grey" mt={2}>
        {t('common.loading')}
      </EdTypography>
    </Container>
  );
};

const Loading = styled(Lottie)`
  height: 35%;
`;

const Container = styled(motion(Box))`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.APP_LAUNCH};
`;
