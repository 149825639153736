import * as React from 'react';

import { AnimatePresence } from 'framer-motion';
import styled, { css } from 'styled-components';

import { Box, NotificationsIcon, EdTypography } from '@edapp/ed-components';
import { nonProxyMotion } from '@maggie/config/motion-wrapper';

type Props = {
  show: boolean;
  text: string;
  isOn: boolean;
};

const Toast: React.FC<Props> = props => {
  return (
    <AnimatePresence>
      {!!props.show && (
        <Container>
          <Background
            initial={{ opacity: 0 }}
            animate={{ opacity: !!props.show ? 0.9 : 0 }}
            exit={{ opacity: 0 }}
          >
            <Box
              flex={true}
              alignItems="center"
              justifyContent="flex-start"
              py="xs"
              pl="sm"
              pr="md"
            >
              <IconWrapper isOn={props.isOn}>
                <NotificationsIcon color={props.isOn ? 'blue' : 'textMuted'} size="md" />
              </IconWrapper>

              <Text>{props.text}</Text>
            </Box>
          </Background>
        </Container>
      )}
    </AnimatePresence>
  );
};

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  ${({ theme }) =>
    `top: calc(constant(safe-area-inset-top) + ${theme.getNavbarHeight()}px + ${theme.space()}px)`};
  ${({ theme }) =>
    `top: calc(env(safe-area-inset-top) + ${theme.getNavbarHeight()}px + ${theme.space()}px)`};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Text = styled(EdTypography)`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.fixedWhite};
  margin-left: ${({ theme }) => theme.space()}px;
`;

const Background = styled(nonProxyMotion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;

  max-width: 225px;
  min-height: 49px;
  width: auto;
  background-color: ${({ theme }) => theme.colors.navy};
  box-shadow: 0 9px 22px 0 rgba(0, 0, 0, 0.5);
  border-radius: 49px;
`;

const IconWrapper = styled.div<{ isOn: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${({ theme, isOn }) =>
    !isOn &&
    // the diagonal line behind the icon when turned off
    css`
      :after {
        content: '';
        position: absolute;
        border-top: 2px solid ${theme.colors.grey};
        width: 150%;
        top: 0;
        left: 0;
        transform: rotate(45deg);
        transform-origin: 0% 0%;
      }
    `}
`;

export { Toast };
