import * as React from 'react';

import styled from 'styled-components';

import { Box, EdTypography } from '@edapp/ed-components';
import type { DemographicQuestionType } from '@maggie/store/learner-demographic/types';

import { RadioTextArea } from '../radio-text-area/RadioTextArea';
import { Radio } from '../radio/Radio';
import { TextArea } from '../text-area/TextArea';
import type { FieldValueType, FieldsValueStateType } from './types';
import { changeValue } from './utils';

type Props = {
  questionTitle: string;
  questionType: DemographicQuestionType['type'];
  onChange: (newValues: FieldsValueStateType) => void;
  formState: FieldsValueStateType;
};

const DemographicQuestionForm: React.FC<Props> = ({
  questionTitle,
  questionType,
  formState,
  onChange
}) => {
  const handleChange = (name: string, value: FieldValueType) => {
    switch (questionType) {
      case 'SingleChoice':
        return onChange(changeValue(formState, name, value));

      case 'FreeText':
      default:
        return onChange({ [name]: value });
    }
  };

  return (
    <FormContainer flex={true} flexDirection="column" flexGrow={1}>
      <Box my="sm">
        <Title>{questionTitle}</Title>
      </Box>

      <Box mb="sm">
        <form>
          {Object.keys(formState).map(name => {
            const field = formState[name];

            switch (field.type) {
              case 'text-area':
                return <TextArea key={name} name={name} field={field} onChange={handleChange} />;

              case 'radio-text-area':
                return (
                  <RadioTextArea key={name} name={name} field={field} onChange={handleChange} />
                );

              case 'radio':
              default:
                return <Radio key={name} name={name} field={field} onChange={handleChange} />;
            }
          })}
        </form>
      </Box>
    </FormContainer>
  );
};

const FormContainer = styled(Box)`
  width: 100%;
`;

const Title = styled(EdTypography)`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
`;

export { DemographicQuestionForm };
