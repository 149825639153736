import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { itly } from '@edapp/analytics-tracking';
import { Box, Loading, EdTypography } from '@edapp/ed-components';
import { useWebviewUxpContext } from '@edapp/uxp-webview';
import { SearchActions } from '@maggie/store/search/actions';
import { SearchSelectors } from '@maggie/store/search/selectors';
import type { SearchItem } from '@maggie/store/search/types';

import { RecentSearches } from './RecentSearches';
import { SearchList } from './search-list/SearchList';
import { getPathFromSearchItem } from './utils';

type Props = {
  className?: string;
};

export const SearchContent: React.FC<Props> = React.memo(({ className }) => {
  const { t } = useTranslation('learners-experience');
  const dispatch = useDispatch();
  const searchTerm = useSelector(SearchSelectors.getSearchTerm);
  const isAutosuggest = useSelector(SearchSelectors.getIsPreviewMode);
  const isLoadingCatalog = useSelector(SearchSelectors.getIsLoadingCatalog);
  const isFilteringCatalog = useSelector(SearchSelectors.getIsFilteringCatalog);
  const filteredItems = useSelector(SearchSelectors.getFilteredItems, shallowEqual);

  const { openWebview } = useWebviewUxpContext();

  const handleItemClicked = (item: SearchItem) => {
    const props = {
      id: item.id,
      title: item?.title || '',
      description: item?.description || '',
      type: item?.type || '',
      thumbnail: item?.thumbnail || ''
    };
    if (isAutosuggest) {
      itly.searchAutoSuggestItemClicked(props);
    } else {
      itly.searchResultClicked(props);
    }

    const path = getPathFromSearchItem(item);
    const redirected = openWebview(path);
    if (!redirected) {
      dispatch(SearchActions.selectSearchItem(item));
    }
  };

  if (!searchTerm) {
    return (
      <Container className={className} textAlign="left">
        <RecentSearches />
      </Container>
    );
  }

  if ((!!isLoadingCatalog && filteredItems.length === 0) || !!isFilteringCatalog) {
    return (
      <Box p={4} flex={true} alignItems="center" justifyContent="center" className={className}>
        <Loading />
      </Box>
    );
  }

  return (
    <Container className={className}>
      {!isAutosuggest && filteredItems.length > 0 && (
        <StyledTypography m={2} variant="normal" textAlign="left" testId="searchResults">
          {t('home.search-results', {
            count_results: filteredItems.length,
            search_term: searchTerm
          })}
        </StyledTypography>
      )}

      <Box flex flexDirection="row" flexWrap="wrap" justifyContent="space-around">
        <SearchList
          searchTerm={searchTerm}
          items={filteredItems}
          condensed={isAutosuggest}
          highlightSearchTerms={isAutosuggest}
          onItemClicked={handleItemClicked}
        />
      </Box>
    </Container>
  );
});

const StyledTypography = styled(EdTypography)`
  display: block;
`;

const Container = styled(Box)`
  box-sizing: border-box;
`;
