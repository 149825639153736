import * as React from 'react';

import { useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';

import { EdTypography } from '@edapp/ed-components';
import { AppLayoutContext } from '@maggie/layout/AppLayoutContext';
import { useIsMobile } from '@maggie/layout/app-mobile-context/useIsMobile';

import { ViewContext } from '../ViewContext';
import { HamburgerIcon } from './HamburgerIcon';

export const MenuToggle: React.FC = React.memo(() => {
  const { t } = useTranslation('learners-experience');
  const theme = useTheme();
  const {
    hasBackButton,
    onGoBack,
    setIsAppMenuOpen,
    isAppMenuOpen,
    hideNavigation
  } = React.useContext(AppLayoutContext);
  const { viewHeadTextColor, viewHeadType } = React.useContext(ViewContext);

  const isMobile = useIsMobile();

  const handleToggleMenu = () => {
    if (hasBackButton) {
      return onGoBack();
    }
    setIsAppMenuOpen(!isAppMenuOpen);
  };

  if (hideNavigation && !hasBackButton) {
    // restricted
    return null;
  }

  const color = viewHeadType === 'subtle' ? viewHeadTextColor : theme.colors.text;
  return (
    <Menu
      data-testid="menu-toggle"
      id="menu-toggle"
      onClick={handleToggleMenu}
      // we force color style here because there is a re-painting issue for ios
      style={{ color }}
    >
      <HamburgerIcon shouldAnimate={hasBackButton} />

      {hasBackButton && !isMobile && (
        <EdTypography variant="subtext2" as="span" ml={0.5} testId="menu-back-text">
          {t('generic.back')}
        </EdTypography>
      )}
    </Menu>
  );
});

const Menu = styled.button(
  ({ theme }) => css`
    display: flex;
    border: none;
    background-color: transparent;
    padding-left: ${theme.space(1.5)}px;
    z-index: 10;
    cursor: pointer;
  `
);
