import styled from 'styled-components';

import { EdTypography } from '@edapp/ed-components';
import { StyledDialog as Dialog } from '@maggie/components/dialogs/styled';

export const PrivacyDialog = styled(Dialog)`
  background-color: ${({ theme }) => theme.colors.navyHover};
  color: ${({ theme }) => theme.colors.white};

  /* above the sky */
  z-index: ${({ theme }) => theme.zIndex.PRIVACY_DIALOG};

  a, ${EdTypography} {
    color: ${({ theme }) => theme.colors.white};
  }

  box-sizing: border-box;
  a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const StyledPreWrapTypography = styled(EdTypography)`
  overflow-y: auto;
  white-space: pre-wrap;
`;
