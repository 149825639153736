import * as React from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, Loading, EdTypography } from '@edapp/ed-components';
import { Platform } from '@maggie/cordova/platform';

type Props = {
  isShowText?: boolean;
  isCentered?: boolean;
};

export const LoadingBanner: React.FC<Props> = ({ isShowText = false, isCentered = false }) => {
  if (isCentered)
    return (
      <LoadingWrapper flex flexDirection="column" justifyContent="center" alignItems="center">
        <LoadingComponent isShowText={isShowText} />
      </LoadingWrapper>
    );
  return <LoadingComponent isShowText={isShowText} />;
};

const LoadingComponent: React.FC<{ isShowText?: boolean }> = ({ isShowText }) => {
  const { t } = useTranslation('learners-experience');
  return (
    <>
      <LoadingIcon testId="loading-icon" />
      {isShowText && (
        <EdTypography color="grey" variant="paragraph">
          {t('common.loading')}
        </EdTypography>
      )}
    </>
  );
};

const LoadingIcon = styled(Loading).attrs({ size: Platform.isMobile() ? 40 : 48 })`
  box-sizing: border-box;
  margin: 1em;
  text-align: center;

  padding-top: ${({ theme }) => theme.getNavbarHeight()}px;
  padding-top: calc(constant(safe-area-inset-top) + ${({ theme }) => theme.getNavbarHeight()}px);
  padding-top: calc(env(safe-area-inset-top) + ${({ theme }) => theme.getNavbarHeight()}px);
`;

const LoadingWrapper = styled(Box)`
  height: 100%;
  width: 100%;
`;
