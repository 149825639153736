import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, EdTypography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import { generateTitle } from '@edapp/translations/src/i18n';
import { StyledDialog, StyledDialogActionButton } from '@maggie/components/dialogs/styled';

type Props = {
  open: boolean;
  onConfirm: () => void;

  title?: string;
  message?: string;
  confirm?: string;
  onCancel?: () => void;
};

const ErrorDialog: React.FC<Props> = ({
  open = false,
  title,
  message,
  confirm,
  onConfirm,
  onCancel
}) => {
  const { t } = useTranslation('learners-experience');
  const onClose = () => {
    onCancel?.();
  };
  return (
    <StyledDialog isOpen={open} onClose={onClose}>
      <Box p="md" style={{ textAlign: 'center' }}>
        <Text component="h2" variant="headlineSmall">
          {title || generateTitle('dialog.error.titles')}
        </Text>
        <EdTypography color="text">{message || t('dialog.error.message')}</EdTypography>
      </Box>

      <StyledDialogActionButton onClick={onConfirm} variant="transparent">
        {confirm || t('dialog.error.confirm')}
      </StyledDialogActionButton>

      {!!onCancel && (
        <StyledDialogActionButton onClick={onCancel} variant="transparent">
          {t('error.dialog.cancel')}
        </StyledDialogActionButton>
      )}
    </StyledDialog>
  );
};

export { ErrorDialog };
