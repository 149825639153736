import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EdTypography, Button, Box } from '@edapp/ed-components';
import styled from 'styled-components';

type Props = {
  message?: string;
  onRetry: () => void;
};

export const GenericErrorFallback: React.FC<Props> = ({ message, onRetry }) => {
  const { t } = useTranslation('learners-experience');
  return (
    <Container
      bgColor="white"
      boxShadow="shallow"
      flex
      flexDirection="column"
      alignItems="center"
      p={3}
      mt={8}
    >
      <EdTypography textAlign="center" color="text" mb={2}>
        {message || t('error-boundary.message')}
      </EdTypography>

      <EdTypography textAlign="center" color="text" mb={2}>
        {t('error-boundary.contact')}
      </EdTypography>

      <Button variant="pale-dark" onClick={onRetry}>
        {t('common.retry')}
      </Button>
    </Container>
  );
};

const Container = styled(Box)`
  max-width: 400px;

  margin-right: auto;
  margin-left: auto;
`;
