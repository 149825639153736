import * as React from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, EdTypography, usePrevious } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import { nonProxyMotion } from '@maggie/config/motion-wrapper';

import { BrainBoostToastContent } from './BrainBoostToastContent';

type Props = {
  show: boolean;
  streak: number;
};

const animText = {
  opacity: [0, 1],
  y: [50, 0],
  transition: {
    duration: 0.5,
    delay: 0.1
  }
};

const BrainboostToast: React.FC<Props> = props => {
  const { t } = useTranslation('learners-experience');
  const [streak, setStreak] = React.useState(props.streak);
  const previousStreak = usePrevious(streak) ?? 0;

  React.useEffect(() => {
    setStreak(props.streak);
  }, [props.streak]);

  const hideContainer = (streak === 0 && previousStreak === 0) || !props.show;
  return (
    <AnimatePresence>
      {!!props.show && (
        <Container>
          <Background
            initial={{ opacity: 0 }}
            animate={{ opacity: !hideContainer ? 0.9 : 0 }}
            exit={{ opacity: 0 }}
          >
            <Box
              flex={true}
              alignItems="center"
              justifyContent="flex-start"
              py="xs"
              pl="sm"
              pr="sm"
            >
              <BrainBoostToastContent streak={streak} previousStreak={previousStreak} />
            </Box>
            <motion.div animate={animText}>
              <Box flex flexDirection="column" textAlign="center">
                <EdTypography color="white">{t('brain-boost.streak')}</EdTypography>
                <Text variant="headlineSmall" color="white.default">
                  {streak}
                </Text>
              </Box>
            </motion.div>
          </Background>
        </Container>
      )}
    </AnimatePresence>
  );
};

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  ${({ theme }) => `
    top: calc(constant(safe-area-inset-top) + ${theme.getNavbarHeight()}px + ${theme.space(2)}px);
    top: calc(env(safe-area-inset-top) + ${theme.getNavbarHeight()}px + ${theme.space(2)}px);
    
    @media screen and (min-width: ${theme.screens.screenMdMin - 1}px) {
      top: calc(constant(safe-area-inset-top) + ${theme.getNavbarHeight()}px + ${theme.space(6)}px);
      top: calc(env(safe-area-inset-top) + ${theme.getNavbarHeight()}px + ${theme.space(6)}px);
    }
    `};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex.BRAIN_BOOST_TOAST};
`;

const Background = styled(nonProxyMotion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  ${({ theme }) => `
    max-width: 400px;
    min-height:  96px;
    background-color: ${theme.colors.black};
    border-radius: ${theme.sizes.borderRadius * 2}px;
    padding-right:  ${theme.space(3)}px;
  `}
  width: 90%;
  opacity: 85%;
  box-sizing: border-box;
`;

export { BrainboostToast };
