import * as React from 'react';

import { intlFormatDistance } from 'date-fns';
import { sanitize } from 'dompurify';

import { Avatar, Box, Skeleton, EdTypography } from '@edapp/ed-components';

import { useNotifications } from '../../providers/notifications';
import { StyledBlueDot, StyledMarkdown, StyledNotificationCard } from './styled';
import type { Actor, Props } from './types';
import { createUriFromBlock, getActorName } from './utils';

export const NotificationCard = React.memo<Props>(
  ({ notification }) => {
    const {
      markNotificationAsSeem,
      markNotificationAsRead,
      onNotificationClick
    } = useNotifications();

    React.useEffect(() => {
      markNotificationAsSeem(notification);
      markNotificationAsRead(notification);
    }, []);

    const uri = createUriFromBlock(notification.blocks?.[1]);
    const handleNotificationClick = React.useCallback(
      (event: React.MouseEvent<HTMLDivElement & HTMLAnchorElement, MouseEvent>) => {
        if (onNotificationClick) {
          event.preventDefault();
          event.stopPropagation();
          onNotificationClick?.(uri);
        }
      },
      [uri]
    );

    const markdown = notification.blocks?.[0]?.rendered;
    const actorName = getActorName(notification.actors?.[0] as Actor);
    const timestamp = intlFormatDistance(new Date(notification.inserted_at), new Date(), {
      locale: navigator.language
    });

    return (
      <StyledNotificationCard as="a" href={uri} onClick={handleNotificationClick}>
        {!notification.read_at && <StyledBlueDot />}

        <Avatar alt={actorName} />

        <Box ml={1} mt={0.125}>
          <StyledMarkdown dangerouslySetInnerHTML={{ __html: sanitize(markdown) }} />

          <EdTypography as="p" variant="subtext" color="textMuted" mt={1}>
            {timestamp}
          </EdTypography>
        </Box>
      </StyledNotificationCard>
    );
  },
  (prevProps, nextProps) => prevProps.notification.id === nextProps.notification.id
);

export const NotificationCardSkeleton = () => (
  <Box flex py={2} px={3} mb={1}>
    <Skeleton variant="circular" width={32} />

    <Box ml={1}>
      <Skeleton variant="text" rows={2} width={350} />

      <Skeleton variant="text" width={100} />
    </Box>
  </Box>
);
