import * as React from 'react';
import styled, { css } from 'styled-components';
import { EdTypography } from '../../typography/EdTypography';
import { HiddenRadio, RadioIcon, RadioLabel } from './styled';
import type { Props } from './types';

export const RADIO_TEST_ID = 'radio';
export const RADIO_LABEL_TEST_ID = 'radio-label';

const RadioComponent: React.FC<Props> = ({
  testId = RADIO_TEST_ID,
  labelTestId = RADIO_LABEL_TEST_ID,
  checked,
  className,
  title,
  description,
  children,
  disabled,
  ...rest
}) => (
  <label data-testid={labelTestId} className={className}>
    <HiddenRadio type="radio" checked={checked} disabled={disabled} testId={testId} {...rest} />
    <RadioIcon disabled={disabled} checked={checked} data-testid="radio-icon" />
    {(title || description) && (
      <RadioLabel variant="subtitle1" disabled={disabled}>
        {title}
        {description && (
          <EdTypography color="textMuted" variant="small">
            {description}
          </EdTypography>
        )}
      </RadioLabel>
    )}
    {children && (
      <RadioLabel variant="normal" disabled={disabled}>
        {children}
      </RadioLabel>
    )}
  </label>
);

export const Radio = styled(RadioComponent).attrs(({ disabled, isDisabled }) => ({
  disabled: disabled || isDisabled
}))<Props>`
  ${({ alignItems = 'center', theme }) => css`
    margin-bottom: ${theme.space(2)}px;
    align-items: ${alignItems};
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer;

    ${alignItems === 'flex-start' &&
    css`
      ${RadioIcon} {
        margin-top: ${theme.space(0.5)}px;
      }
    `}
  `};
`;
