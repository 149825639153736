import * as React from 'react';
import { RadioIcon, Radio as EdRadio, EdTypography } from '@edapp/ed-components';
import { rgba } from '@edapp/themes';
import styled, { css } from 'styled-components';
import type { RadioField } from '../demographic-question-form/types';

type Props = {
  name: string;
  field: RadioField;
  onChange: (name: string, value: RadioField) => void;
};
export const Radio: React.FC<Props> = props => {
  const onInternalChange = (e: React.FormEvent<HTMLInputElement>) => {
    props.onChange(props.name, { ...props.field, value: e.currentTarget.checked ?? undefined });
  };

  return (
    <StyledRadio
      name={props.name}
      onChange={onInternalChange}
      checked={props.field.value || false}
      isSelected={props.field.value}
    >
      {props.field.label}
    </StyledRadio>
  );
};

const StyledRadio = styled(EdRadio)<{ isSelected: boolean | undefined }>(
  ({ theme, isSelected }) => css`
    box-sizing: border-box;
    padding: ${theme.space(2)}px;

    border: 1px solid ${theme.colors.white};
    border-radius: 4px;
    background-color: ${rgba(theme.colors.white, 0.16)};

    cursor: pointer;
    width: 100%;
    text-transform: none !important;

    transition: all 300ms ease-in-out;

    & ${RadioIcon} {
      width: 1rem;
      height: 1rem;
      :after {
        background-color: ${theme.colors.white};
      }
    }

    & ${EdTypography} {
      color: ${theme.colors.white};
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.1875rem;
    }

    ${!!isSelected && `box-shadow: 0 10px 12px -1px rgba(0,0,0,0.12);`}

    opacity: 1;
    ${isSelected === false && `opacity: 0.6;`}
  `
);
