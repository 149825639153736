import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, NoSearchResultSvg, EdTypography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';

import { SearchCard } from '../search-card/SearchCard';

import type { SearchItem } from '@maggie/store/search/types';

type Props = {
  /** Used to display text highlight */
  searchTerm: string;
  /** Items to be suggested */
  items: SearchItem[];
  /** Highlights in the text the words that match the search term */
  highlightSearchTerms?: boolean;
  /** Shows small items */
  condensed?: boolean;
  /** Callback to action when item is clicked */
  onItemClicked?: (item: SearchItem) => void;
};

export const SearchList: React.FC<Props> = React.memo(
  ({ searchTerm, items, highlightSearchTerms, condensed, onItemClicked }) => {
    const { t } = useTranslation('learners-experience');
    if (items.length === 0) {
      return (
        <Box flex flexDirection="column" alignItems="center" py="lg">
          <NoSearchResultSvg />
          <Text variant="titleLarge" component="h2" textAlign="center">
            {t('home.search-no-results-title')}
          </Text>
          <EdTypography variant="small" color="textMuted" textAlign="center">
            {t('home.search-no-results-subtitle')}
          </EdTypography>
        </Box>
      );
    }

    return (
      <>
        {items.map(item => (
          <SearchCard
            key={item.id}
            item={item}
            condensed={condensed}
            highlightedSearchTerm={!!highlightSearchTerms ? searchTerm : undefined}
            onClick={onItemClicked}
          />
        ))}
      </>
    );
  }
);
