import * as React from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, Button, Divider, EdTypography, WarningIcon } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import { rgba } from '@edapp/themes';
import { StyledDialog as Dialog } from '@maggie/components/dialogs/styled';
import { Platform } from '@maggie/cordova/platform';
import { globalZIndex } from '@maggie/theme/globalZIndex';

type Props = {
  isOpen: boolean;
  version: string;
  onUpdate: () => void;
  onCancel: () => void;
};

// TODO: https://safetyculture.atlassian.net/browse/TRAINING-515
const UpdateAppDialog: React.FC<Props> = ({ isOpen, version, onUpdate, onCancel }) => {
  const { t } = useTranslation('learners-experience');
  return (
    <StyledDialog isOpen={isOpen} dialogWrapperStyle={{ zIndex: globalZIndex.APP_UPDATE }}>
      <Box flex={true} flexDirection="column">
        <Box mt="lg">
          <StyledWarningIcon />
        </Box>

        <Box mt="lg" mx="sm">
          <Text variant="labelLarge" component="h4">
            t('update-app-dialog.title')
          </Text>
        </Box>

        <Box mt="xs" mx="sm">
          <EdTypography
            variant="normal"
            color="textMuted"
            children={t('update-app-dialog.description', {
              version,
              store_name: Platform.get() === 'Android' ? 'Play Store' : 'App Store'
            })}
          />
        </Box>

        <Box my="md" textAlign="center">
          <UpdateButton
            size="lg"
            variant="primary"
            children={t('update-app-dialog.button')}
            onClick={onUpdate}
          />
        </Box>

        <Divider color="navy" />

        <NotNowButton size="lg" variant="transparent" isFullWidth={true} onClick={onCancel}>
          {t('update-app-dialog.cancel-button')}
        </NotNowButton>
      </Box>
    </StyledDialog>
  );
};

const UpdateButton = styled(Button)`
  width: 190px;
`;

const NotNowButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};

  :enabled {
    :hover,
    :focus {
      background-color: ${({ theme }) => rgba(theme.colors.white, 0.1)};
    }
  }
`;

const StyledDialog = styled(Dialog)`
  text-align: center;
  background-color: ${({ theme }) => theme.colors.navyHover};
  color: ${({ theme }) => theme.colors.white};

  /* above the sky */
  z-index: 9999;
`;

const StyledWarningIcon = styled(WarningIcon)`
  color: ${({ theme }) => theme.colors.orange};
  width: 60px;
  height: 60px;
`;

export { UpdateAppDialog };
