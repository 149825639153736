import * as React from 'react';

import * as DOMPurify from 'dompurify';
import styled, { css } from 'styled-components';

import { Box, Image, EdTypography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import { Asset } from '@maggie/core/lessons/asset';
import type { SearchEntityType, SearchItem } from '@maggie/store/search/types';

type Props = {
  item: SearchItem;
  /**
   * shows a smaller card without background-color
   * @default `false`
   */
  condensed?: boolean;
  /**
   * shows the text of the card with bold highlighting search term
   * @default `false`
   */
  highlightedSearchTerm?: string;
  onClick?: (item: SearchItem) => void;
};

const SearchCard: React.FC<Props> = ({
  item,
  onClick,
  highlightedSearchTerm,
  condensed = false
}) => {
  const handleClick = () => {
    onClick?.(item);
  };

  const itemType = getReadableSearchType(item.type);

  if (!itemType) {
    // If client side is not aware of that type, do not render card.
    return null;
  }

  return (
    <Container flexGrow={1} my={1.5}>
      <Wrapper
        onClick={handleClick}
        flex={true}
        flexDirection="row"
        bgColor="transparent"
        condensed={condensed}
        p={2}
      >
        <ImageContainer condensed={condensed} hasImage={!!item.thumbnail}>
          {item.thumbnail && (
            <StyledImage src={new Asset(item.thumbnail, 'lessons', '').remoteURL} />
          )}
        </ImageContainer>

        <Box flex={true} flexDirection="column" ml={2} justifyContent="space-between">
          <Box flex={true} flexDirection="column">
            {!!condensed ? (
              <StyledTypography nLines={2} textAlign="left">
                <TextElement searchTerm={highlightedSearchTerm} text={item.title} />
              </StyledTypography>
            ) : (
              <Text variant="titleMedium" component="h3" clamp={2} textAlign="left">
                <TextElement searchTerm={highlightedSearchTerm} text={item.title} />
              </Text>
            )}

            {!!item.description && (
              <StyledTypography
                variant={!!condensed ? 'subtext' : 'normal'}
                color={!!condensed ? 'textMuted' : 'text'}
                nLines={!!condensed ? 1 : 2}
                textAlign="left"
              >
                <TextElement searchTerm={highlightedSearchTerm} text={item.description} />
              </StyledTypography>
            )}
          </Box>

          {!condensed && (
            <Tag mt="xs" px={1} py={0.5}>
              {itemType}
            </Tag>
          )}
        </Box>
      </Wrapper>
    </Container>
  );
};

const getReadableSearchType = (type: SearchEntityType) => {
  switch (type) {
    case 'Course':
      return 'Course';

    case 'CourseCollection':
      return 'Course Collection';

    case 'Lesson':
      return 'Lesson';

    case 'Conference':
      return 'Conference';

    case 'Discussion':
      return 'Discussion';

    case 'Assignment':
      return 'Assignment';

    case 'Observation':
      return 'Observation';

    case 'Playlist':
      return 'Playlist';

    case 'BriefcaseDocument':
      return 'Briefcase Document';

    default:
      console.error('unknown type of search entity' + type);
      return '';
  }
};

const Wrapper = styled(Box)<{ condensed: boolean }>`
  overflow: hidden;
  border-radius: ${({ theme }) => theme.sizes.borderRadius}px;
  ${({ theme, condensed }) =>
    !condensed &&
    css`
      border: 1px solid ${theme.colors.greyHover};
    `}
`;

const Tag = styled(Box)`
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border-radius: ${({ theme }) => theme.sizes.borderRadius}px;
  align-self: flex-start;
  font-size: 0.75rem;
  line-height: 1rem;
`;

const StyledTypography = styled(EdTypography)<{ nLines: number }>`
  ${({ theme, nLines }) => theme.lineClamp(nLines)};
  line-height: 1.45;
`;

const ImageContainer = styled.div<{ condensed: boolean; hasImage: boolean }>`
  flex-shrink: 0;

  width: ${({ condensed }) => (!!condensed ? '45px' : '140px')};
  height: ${({ condensed }) => (!!condensed ? '45px' : '140px')};

  @media (max-width: ${props => props.theme.screens.screenMdMin}px) {
    width: ${({ condensed }) => (!!condensed ? '45px' : '90px')};
    height: ${({ condensed }) => (!!condensed ? '45px' : '90px')};
  }

  ${({ theme, hasImage }) => !hasImage && `background-color: ${theme.colors.blue};`}

  border-radius: ${({ theme }) => theme.sizes.borderRadius}px;
  position: relative;
  overflow: hidden;
`;

const StyledImage = styled(Image)`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const Container = styled(Box)`
  @media (min-width: ${props => props.theme.screens.screenMdMin}px) {
    display: inline-block;
    width: 480px;
    max-width: 480px;
    vertical-align: middle;
    box-sizing: border-box;
  }

  width: 100%;
  border-radius: 3px;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

/**
 * Reference: https://github.com/sindresorhus/escape-string-regexp/blob/main/index.js
 */
export default function escapeStringRegexp(value: string) {
  if (typeof value !== 'string') {
    return '';
  }

  // Escape characters with special meaning either inside or outside character sets.
  // Use a simple backslash escape when it’s always valid, and a `\xnn` escape when the simpler form would be disallowed by Unicode patterns’ stricter grammar.
  return value.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
}

type TextElementProps = {
  searchTerm?: string;
  text?: string;
};
export const TextElement: React.FC<TextElementProps> = ({ searchTerm, text }) => {
  if (!searchTerm) {
    return <>{text || ''}</>;
  }

  const searchTermRegex = new RegExp(escapeStringRegexp(searchTerm), 'ig');

  if (!text || !text.match(searchTermRegex)) {
    return <>{text || ''}</>;
  }

  const replacedText = text.replace(
    searchTermRegex,
    t => `<span style="font-weight: 300;">${t}</span>`
  );

  return (
    <b
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(replacedText) }}
    />
  );
};

export { SearchCard };
