import * as React from 'react';

import styled, { css, keyframes } from 'styled-components';

import { EdTypography } from '@edapp/ed-components';
import STAR_IMAGE from '@maggie/assets/img/star.png';
import { checkOnline } from '@maggie/cordova/network_utils';

import { StarBalanceOfflineDialog } from './StarBalanceOfflineDialog';

const MAX_ANIMATION_ITERATIONS = 6;
const MIN_ANIMATION_DURATION = 300;

type Props = {
  /**
   * The amount of stars
   */
  balance: number;
  /**
   * Tells if user can click and navigate to star bar
   */
  isGameAvailable: boolean;
};

export const StarBalance: React.FC<Props> = ({ balance, isGameAvailable }) => {
  const [currentBalance, setCurrentBalance] = React.useState<number>(balance);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [animation, setAnimation] = React.useState<string | null>(null);

  React.useEffect(() => {
    const iterations = Math.min(Math.abs(balance - currentBalance), MAX_ANIMATION_ITERATIONS);
    const duration = Math.max(120 * iterations, MIN_ANIMATION_DURATION);
    const direction = balance - currentBalance < 0 ? 'forwards' : 'reverse';

    const animation = `${duration / iterations}ms ${iterations} ${direction} linear`;
    const timer = window.setTimeout(() => setAnimation(null), 3000);
    setAnimation(animation);
    setCurrentBalance(balance);

    return () => window.clearTimeout(timer);
  }, [balance]);

  const onCloseDialog = () => {
    setDialogOpen(false);
  };

  const onClick = () => {
    if (!checkOnline()) {
      setDialogOpen(true);
      return;
    }

    if (!isGameAvailable) {
      return;
    }

    window.__router.navigate('game', {});
  };

  return (
    <>
      <StarBalanceOfflineDialog open={dialogOpen} onClose={onCloseDialog} />

      <div id="star-view" data-testid="star-view">
        <LinkWrapper onClick={onClick} isActive={isGameAvailable}>
          <EdTypography variant="subtitle2" testId="star-balance-view-token-count">
            {currentBalance}
          </EdTypography>
          <StarAnimation animation={animation} />
        </LinkWrapper>
      </div>
    </>
  );
};

const StarAnimation: React.FC<{ animation: string | null }> = React.memo(({ animation }) => (
  <Star animation={animation} data-testid="star-balance-view-token-star" />
));

const LinkWrapper = styled.a<{ isActive: boolean }>(
  ({ theme, isActive }) => css`
    background-color: ${theme.colors.lightGrey};
    height: 32px;
    border-radius: ${theme.sizes.borderRadius}px;
    padding: 0 ${theme.space(1.5)}px;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-right: ${theme.space()}px;
    color: ${theme.colors.text};
    cursor: ${isActive ? 'pointer' : 'default'};
    &:focus {
      outline: none;
    }

    .subtle-navbar div & {
      background-color: ${theme.colors.navy};
      color: ${theme.colors.white};
      backdrop-filter: blur(3px);
    }
  `
);

const changestars = keyframes`
  from {
    transform: translate3d(0, 26px, 0)
  }
  to {
    transform: translate3d(0, -26px, 0)
  }
`;

const Star = styled.span<{ animation: null | string }>(
  ({ theme, animation }) => css`
    margin: 0 0 0 ${theme.space(0.5)}px;
    width: 20px;
    height: 20px;
    background: url(${STAR_IMAGE}) no-repeat top center;
    background-size: 20px;
    animation: none;
    ${!!animation &&
    css`
      animation: ${changestars} ${animation};
      background-image: url(${STAR_IMAGE}), url(${STAR_IMAGE}), url(${STAR_IMAGE});
      background-position: 0 0px, 0 26px, 0 52px;
      height: 72px; // 3*20px + 6px margin between stars
    `};
  `
);
