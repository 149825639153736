import styled, { css } from 'styled-components';
import { Input } from '../input';
import { EdTypography } from '../../typography/EdTypography';
import type { Props } from './types';

export const HiddenRadio = styled(Input)`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const RadioIcon = styled.div<Pick<Props, 'disabled' | 'checked'>>`
  ${({ theme, checked, disabled }) => css`
    position: relative;
    min-width: ${theme.space(2)}px;
    min-height: ${theme.space(2)}px;
    transition: all ${theme.transition.slow};
    border-radius: 50%;
    border: 2px solid ${theme.colors.grey};
    :hover {
      border-color: ${theme.colors.greyHover};
    }
    :focus {
      border-color: ${theme.colors.blue};
    }

    ${checked &&
    css`
      border-color: ${theme.colors.blue};
      :after {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${theme.colors.blue};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3D(-50%, -50%, 0);
      }
    `}

    ${disabled &&
    css`
      border-color: ${theme.colors.grey};
      background: ${theme.colors.lightGrey};
      :after {
        background-color: ${theme.colors.greyHover};
      }
    `}
  `}
`;

export const RadioLabel = styled(EdTypography)<Pick<Props, 'disabled'>>`
  ${({ theme, disabled }) => css`
    color: ${disabled ? theme.colors.textMuted : theme.colors.text};
    margin-left: ${theme.space(2)}px;
    display: flex;
    flex-direction: column;
    user-select: none;
    flex: 1 1 100%;
  `}
`;
